<template>
  <div>
    <vs-tabs alignment="fixed" class="w-full">
      <vs-tab label="نوتیفیکیشن">
        <vx-card>
          <h2 class="mar flex justify-center">نوتیفیکیشن</h2>
          <div class="flex my-5 flex flex-wrap">
            <vs-input
              class="mx-5"
              v-model="NOTIFreceiver_id"
              label-placeholder="ID کاربر"
              type="number"
            ></vs-input>
            <vs-input
              v-model="NOTIFtitle"
              label-placeholder="عنوان نوتیفیکیشن"
            ></vs-input>
          </div>
          <!-- <vs-textarea
            placeholder="متن خود را وارد کنید..."
            class="my-5 w-full"
            v-model="NOTIFdescription"
          ></vs-textarea> -->
          <div class="flex justify-center">
            <quill-editor
              class="border-solid rounded my-10 h-full w-4/5"
              v-model="NOTIFdescription"
              :options="editorOption"
            />
          </div>
          <vs-button @click="storenewnotif"> ساخت نوتیفیکیشن </vs-button>
        </vx-card>
        <vs-divider></vs-divider>
        <!-- ======================================================================================== -->
        <vx-card>
          <vs-input
            @keydown.enter="listallnotif"
            class="my-5"
            v-model="NOTIFsearch"
            label-placeholder="جستجو بر اساس ID"
          ></vs-input>
          <vs-table v-model="SelectedNotif" :data="notiflist">
            <div class="mar flex justify-center">
              <p>test</p>
            </div>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="w-full" :data="data[indextr].description">
                  <div class="flex justify-between">
                    <div class="flex my-3 mx-1">
                      <vs-dropdown vs-custom-content vs-trigger-click>
                        <p class="text-lg font-semibold cursor">
                          {{ data[indextr].title }}
                          <vs-icon icon="arrow_drop_down"></vs-icon>
                        </p>
                        <vs-dropdown-menu class="my-2">
                          <div>
                            <span>
                              <div class="flex flex-wrap justify-between">
                                ID دریافت کننده:
                                <p
                                  @click="$log('pedram')"
                                  class="mx-5 text-success"
                                >
                                  {{ data[indextr].receiver_id }}
                                </p>
                              </div>
                              <div class="flex flex-wrap justify-between">
                                ID سازنده:
                                <p class="mx-5 text-success">
                                  {{ data[indextr].creator_id }}
                                </p>
                              </div>
                              <div class="flex flex-wrap justify-between">
                                ID نوتیف:
                                <p class="mx-5 text-success">
                                  {{ data[indextr].id }}
                                </p>
                              </div>
                            </span>
                          </div>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>
                    <div class="flex">
                      <vs-icon
                        class="w-full mx-1"
                        :color="
                          data[indextr].seen_status ? 'success' : 'danger'
                        "
                        :icon="
                          data[indextr].seen_status
                            ? 'visibility check'
                            : 'visibility'
                        "
                      ></vs-icon>
                      <vs-button
                        class="cursor-pointer w-full"
                        @click="deletenotif(data[indextr].id)"
                        icon="delete"
                        :color="
                          data[indextr].seen_status ? 'success' : 'danger'
                        "
                      ></vs-button>
                    </div>
                  </div>
                  <!-- <div>
                    <p class="text-base flex flex-wrap">
                      {{ data[indextr].description }}
                    </p>
                  </div> -->
                  <div v-html="data[indextr].description"></div>
                  <vs-divider></vs-divider>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-tab>
      <!-- ======================================================================================== -->
      <vs-tab class="my-2" label="پیام عمومی">
        <div>
          <vx-card class="my-4">
            <h2 class="mar flex justify-center">پیام عمومی</h2>
            <div class="flex flex-wrap">
              <vs-input
                v-if="Nmode"
                v-model="Nreceiver_id"
                class="w-1/12 my-5 mx-5"
                label-placeholder="Receive id"
              ></vs-input>
              <vs-input
                class="mx-5"
                v-model="Ntitle"
                label-placeholder="عنوان"
              ></vs-input>
              <!-- <vs-input
            v-model="Nstatus"
            class="mx-5"
            label-placeholder="Status"
          ></vs-input> -->
              <!-- <vs-input
            v-model="Essis_show"
            class="mx-5"
            label-placeholder="is show"
          ></vs-input> -->
              <vs-input
                v-model="Ntext"
                class="mx-5"
                label-placeholder="متن"
              ></vs-input>

              <vs-input
                v-model="Esslink_to"
                class="mx-5"
                label-placeholder="لینک"
              ></vs-input>

              <vs-input
                v-model="Esshow_up_at"
                class="mx-5"
                label-placeholder="تاریخ نمایش"
              ></vs-input>
            </div>
            <vs-textarea
              v-model="Essdescription"
              class="my-5"
              placeholder="توضیحات"
            ></vs-textarea>
            <vs-button @click="storenewessentialmessage"> ساخت پیام </vs-button>
          </vx-card>
        </div>
        <vs-divider></vs-divider>
        <!-- ======================================================================================== -->
        <vx-card>
          <vs-button
            class="my-5"
            size="small"
            :color="wantstodeleteEM ? '#f00' : ''"
            :type="wantstodeleteEM ? 'filled' : 'border'"
            @click="wantstodeleteEM = !wantstodeleteEM"
          >
            Delete
          </vs-button>
          <vs-table search v-model="SelectedNotif" :data="essentiallist">
            <template slot="thead">
              <vs-th> ID </vs-th>
              <vs-th> عنوان </vs-th>
              <vs-th> ایمیل </vs-th>
              <vs-th v-if="wantstodeleteEM"> حذف </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].id }}
                </vs-td>
                <vs-td :data="data[indextr].title">
                  {{ data[indextr].title }}
                </vs-td>
                <vs-td :data="data[indextr].description">
                  {{ data[indextr].description }}
                </vs-td>
                <vs-td v-if="wantstodeleteEM">
                  <vs-button
                    color="danger"
                    icon="delete"
                    type="gradient"
                    @click="deleteessentialmessage(data[indextr].id)"
                    >حذف</vs-button
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <!-- ======================================================================================== -->
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      // ===================== editor
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "link", "blockquote"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        placeholder: "متن ادمین....",
      },
      // ===================== editor

      selectedUser: "",
      //=================ESSENTIAL MESSAGE PART
      Esstitle: "",
      Essdescription: "",
      Essicon: "chat-right-text-fill", //icons => ["chat-right-text-fill"]
      Essstatus: 0, //options = [0,1] default => 1
      Essmode: 0, //options = [0,1]| 0 => essential, 1 => non_essential
      Esstext: "", //nullable
      Esslink_to: "", //clickable link
      Essis_show: 1, //options = BOLEAN ,default => true
      Esshow_up_at: "", //date
      wantstodeleteEM: false,
      //=================ESSENTIAL MESSAGE PART

      //=================NOTIFICATION PART
      SelectedNotif: "",
      NOTIFreceiver_id: "",
      NOTIFtitle: "",
      NOTIFdescription: "",
      NOTIFsearch: "",
      //=================NOTIFICATION PART

      //================= notif table
      notiflist: [],
      //================= notif table

      //================= essential table
      essentiallist: [],
      //================= essential table
    };
  },
  methods: {
    //=====   ESSENTIAL MESSAGE METHODS =========================================================================================================
    storenewessentialmessage() {
      // const data = {
      //   title: this.Ntitle,
      //   icon: this.Nicon,
      //   status: this.Nstatus,
      //   // mode: this.Nmode, // 0/1
      //   mode: 0,
      //   is_show: this.Essis_show,
      //   text: this.Ntext,
      //   link_to: this.Esslink_to,
      //   receiver_id: this.Nreceiver_id,
      //   show_up_at: this.Esshow_up_at,
      // };
      this.$http
        // .post("/admin/EssentialMessage/store", data)
        .post("/admin/EssentialMessage/add", {
          title: this.Ntitle,
          text: this.Ntext,
          status: 0,
          mode: this.Nmode,
          link_to: this.Esslink_to,
          is_show: this.Essis_show,
          receiver_id: this.Nreceiver_id ? this.Nreceiver_id : 55,
        })
        .then((res) => {
          console.log("res ers ers", res);
          if (res.status === 200) {
            this.getallessentials();
            this.$vs.notify({ text: "پیام عمومی ایجاد شد", color: "success" });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
      this.getallessentials();
    },
    getallessentials() {
      this.essentiallist = [];
      this.$http
        .get("/admin/EssentialMessage/list")
        .then((res) => {
          console.log(res);
          this.essentiallist = res.data.params;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteessentialmessage(id) {
      this.$http
        .delete(`/admin/EssentialMessage/delete/${id}`)
        .then((res) => {
          console.log(res);
          this.getallessentials();
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=====   ESSENTIAL MESSAGE METHODS =========================================================================================================

    //=====   NOTIFICATION METHODS ==============================================================================================================
    storenewnotif() {
      this.$vs.loading();
      this.$http
        .post("/admin/notification/store", {
          receiver_id: this.NOTIFreceiver_id,
          title: this.NOTIFtitle,
          description_html: this.NOTIFdescription,
        })
        .then((res) => {
          this.$vs.loading.close();
          // console.log(res);
          this.listallnotif();
          this.$vs.notify({ text: res.data.message, color: "success" });
        })
        .catch((err) => {
          this.$vs.loading.close();
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    listallnotif() {
      this.$http
        .get(`/admin/notifications/?receiver_id=${this.NOTIFsearch}`)
        .then((res) => {
          this.notiflist = res.data.params.data;
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletenotif(id) {
      this.$vs.loading();
      this.$http
        .delete(`/admin/notification/${id}`)
        .then((res) => {
          this.$vs.loading.close();
          // console.log(res);
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.listallnotif();
        })
        .catch((err) => {
          this.$vs.loading.close();
          // console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
    //=====   NOTIFICATION METHODS ==============================================================================================================
  },
  created() {
    this.listallnotif();
    this.getallessentials();
  },
};
</script>
